<template>
  <div >
    <a-form-model ref="ruleForm" :model="post" :rules="rules" v-bind="layout">
      <a-row type="flex" justify="space-around" align="middle">
        <a-col :span="18">
          <a-form-model-item has-feedback prop="sponsor_name" label="姓名" >
            <a-input placeholder="请输入您的姓名" v-model="post.sponsor_name">
            </a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="18">
          <a-form-model-item has-feedback prop="sponsor_phone" label="手机号" >
            <a-input placeholder="请输入11位手机号码" v-model="post.sponsor_phone">
            </a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="18">
          <a-form-model-item has-feedback prop="sponsor_idcard_no" label="身份证号">
            <a-input placeholder="请输入18位身份证号码" v-model="post.sponsor_idcard_no">
            </a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="18" v-if="this.$route.name !== '调解办理'">
          <a-form-model-item has-feedback prop="reserve_handle_time" label="预约时间">
            <a-date-picker :show-time="false" placeholder="请选择时间" style="width: 520px" @change="timeChange"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="18">
          <a-form-model-item has-feedback prop="order_desc" label="描述">
            <a-textarea :placeholder="'您可以概括您的需求,便于' + title + '快速了解您的情况'" v-model="post.order_desc"
            :rows="4"
            >
            </a-textarea>
          </a-form-model-item>
        </a-col>
        <a-col :span="10">
          <div class="applyStepBtn">
              <a  @click="onBack" class="prev">上一步</a>
              <a  @click="onClick" class="next">下一步</a>
          </div>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>
<script>
import model from '@/core/model'
import base from '../../core/base.js'
export default {
  name: 'ApplyForm',
  mixins: [base, model],
  data () {
    return {
      StepStatus: 3,
      util: {
        iconLoading: false,
        disabled: true
      },
      submitData: {
      },
      rules: {
        sponsor_name: [
          { required: true, message: '请输入姓名', trigger: 'change' },
          { mix: 2, max: 6, message: '2-6个中文', trigger: 'change' }
        ],
        sponsor_idcard_no: [
          { required: true, message: '请输入身份证号码', trigger: 'change' },
          { validator: this.idcard18, trigger: 'change' }
        ],
        sponsor_phone: [
          { required: true, message: '请输入手机号码', trigger: 'change' },
          {
            validator: this.enableSendCode,
            trigger: 'change'
          }
        ],
        order_desc: [
          { required: true, message: '此项不能为空', trigger: 'change' }
        ],
        reserve_handle_time: [
          { required: true, message: '预约时间', trigger: 'change' }
        ]
      },
      layout: {
        labelCol: { span: 4, offset: 0 },
        wrapperCol: { span: 16, offset: 0 }
      }
    }
  },
  methods: {
    onSearch () {
      this.util.iconLoading = true
      var that = this
      setTimeout(function () {
        that.util.iconLoading = false
      }, 60000)
    },
    idcard18 (rule, value, callback) {
      const id = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/
      if (id.test(value)) {
        callback()
      } else {
        callback(new Error('格式输入错误'))
      }
    },
    enableSendCode (rule, value, callback) {
      const passwordReg = /^1\d{10}$/
      if (passwordReg.test(value)) {
        this.util.disabled = false
        callback()
      } else {
        this.util.disabled = true
        callback(new Error('手机号码不正确'))
      }
    },
    onClick () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submitData.platform_channel = '门户'
          this.submitData.platform_type = '网络'
          // var result = this.saveData({ url: 'my-workorder/apply', data: this.post })
          this.$api.post('/api/my-workorder/apply', this.post).then((res) => {
            if (res.status === 200) {
              this.$Cookies.set('order_code', res.data.data.order_code)
              this.$emit('setStep', 4)
            } else {
              this.$emit('setStep', 5)
            }
          })
        } else {
          return false
        }
      })
    },
    onBack () {
      this.$emit('setStep', 2)
    },
    timeChange (date, dateString) {
      this.post.reserve_handle_time = new Date(dateString).getTime()
    }
  },
  created () {
    this.submitData = this.post
  }

}
</script>
<style scoped>
</style>
