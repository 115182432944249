<template>
  <Page :title="post.service_name">
    <applyheader :post="post"></applyheader>
    <article id="page-apply" class="mt-4 mb-4">
      <section class="container container-main">
        <div class="applyCon_zzy p-4">
          <div class="applyC" ng-show="selectParams.showtab==1">
            <div class="p-5">
              <Stepbar
                :post="{title:['办理须知','选择机构','资料提交','提交完成'],StepStatus:StepStatus}"
              >
              </Stepbar>
            </div>
            <div  v-if="StepStatus === 1" class="p-4">
              <a-row type="flex" justify="space-around" align="middle">
                <a-col :span="18" class="justify-Wechat">
                  <a-alert
                    message="温馨提示!"
                    :description="'请您仔细阅读办理须知，申请' + post.service_name + '，业务类型选择可参考服务指南，提交申请后平台将会对本次申请进行审核，审核办理情况平台实时发送消息通知，办理进度可在个人中心工单中查看。办理过程种如有问题可选择拨打12348热线电话、在线咨询、留言咨询等方式获得帮助。'"
                    type="warning"
                    show-icon
                  />
                  <div class="Wechat">
                    <img src="../../assets/images/weixin.png" alt="erweima">
                  </div>
                </a-col>
                <a-col :span="22">
                  <div class="risk_inner mb-4 mt-4">
                    <div class="ant-card-head mb-4">
                      <a-icon type="copy" />办理须知:
                    </div>
                    <div v-html="post.info">
                    </div>
                  </div>
                </a-col>
                <a-col :span="4">
                  <a-button type="danger" block @click="setStep(2)">
                    下一步
                  </a-button>
                </a-col>
              </a-row>
            </div>
            <!--预约选择-->
            <div class="step2" v-if="StepStatus === 2">
              <ul class="reservation_ul">
                <li>
                  <a-alert
                    class="m-auto w-7"
                    description="提示：选择办理业务类型（业务类型选择可参考司法鉴定申请指南）；办理地区（该地区应为您的经常居住地）。"
                    type="warning"
                    show-icon
                  />
                  <br>
                  <a-row>
                      <a-col :span="24">
                        <div class="selectop" >
                          <Collection
                            name="business_type"
                            v-slot="{ collection }"
                            :limit="1000"
                            :filter="{ parent: submitData.service_type }"
                          >
                            <div v-if="collection.length > 0">
                              <a-col :span="4"><div class="leftTit">请选择办理的业务:</div></a-col>
                              <a-col :span="8" class="business-apply">
                              <a-select placeholder="业务类型" @change="businessChange($event, collection)">
                                <a-select-option value="">请选择</a-select-option>
                                <a-select-option v-for="item in collection" :key="item.item_value">
                                  {{ item.item_name }}
                                </a-select-option>
                              </a-select>
                              </a-col>
                            </div>
                          </Collection>
                        </div>
                      </a-col>
                    <a-col :span="24">
                    <a-col :span="4"><div class="leftTit">请选择办理地区:</div></a-col>
                    <a-col :span="8">
                      <div class="rightCheck apply-select">
                        <div class="selectop">
                          <Collection  name="city"  v-slot="{ collection }">
                              <a-select class="region-selection" placeholder="请选择市/州" @change="handleChange($event, collection)" >
                              <a-select-option value="" :wrapper-col="{ span: 8, offset: 0 }">请选择</a-select-option>
                              <a-select-option v-for="value in collection" :key="value.district_id">
                                {{ value.district_name }}
                              </a-select-option>
                            </a-select>
                          </Collection>
                        </div>
                      </div>
                    </a-col>
                    <a-col :span="8">
                      <div class="selectop" >
                        <Collection  name="area"    v-slot="{ collection }" :filter="{ parent: citykey }" >
                          <a-select class="wight-judge" placeholder="请选择区/县"  @change="areaChange($event, collection)" v-if="submitData.city !== ''" v-model="changeValue">
                            <a-select-option value="">请选择</a-select-option>
                            <a-select-option v-for="value in collection" :key="value.district_id">
                              {{ value.district_name }}
                            </a-select-option>
                          </a-select>
                        </Collection>
                      </div>
                    </a-col>
                    </a-col>
                  </a-row>
                </li>
                <div class="rightCheck" >
                  <div class="mechanism_list">
                    <a-empty v-if="prodataStatus">
                      <p slot="description"><span>{{ submitData.city }}{{ submitData.area }}暂时没有可以办理的机构</span></p>
                    </a-empty>
                    <a-row :gutter="[16, 16]" class="mt-5" >
                      <a-col
                        :span="6"
                        v-for="(item, index) in prodata"
                        :key="item.provider_id"
                        @click="selectOrg(item.provider_id, index)"
                        :class="{ 'active': currentSelect === index }"
                      >
                        <div class="lawyer">
                          <div class="local red">{{ item.city }}</div>
                          <div class="photo-box">
                            <img :src="getOrgPhotoURL(item.provider_id, item.cover)" />
                          </div>
                          <div class="apply-org-selection">
                            <br>
                          <p class="name">{{ item.provider_name }}</p>
                          <p class="tel">联系电话：{{ item.phone }}</p>
                          <p class="address">地址：{{ item.address }}</p>
                          </div>
                        </div>
                      </a-col>
                    </a-row>
                    <a-pagination
                      class="mb-5"
                      show-quick-jumper
                      :current="provider.current"
                      :defaultPageSize="provider.defaultPageSize"
                      :total="prodata.length"
                      @change="onChange"
                      v-if="this.prodata.length > 16"
                    />
                  </div>
                </div>
              </ul>
              <div class="applyStepBtn">  <a href="javascript:;" class="prev" @click="setStep(1)">上一步</a><a href="javascript:;" class="next" @click="next" >下一步</a> </div>
            </div>
          </div>
          <div class="applyC ng-hide" ng-show="selectParams.showtab==2">
            <!--步骤-->
            <!--资料提交-->
            <ApplyForm ref="StepStatus" @setStep="setStep" :value=StepStatus v-if="StepStatus === 3" :post="submitData" :title="post.type"
            ></ApplyForm>
            <!--下一步-->
          </div>
          <div class="applyC ng-hide">
            <SuccessAndError :StepStatus="StepStatus"></SuccessAndError>
          </div>
        </div>
      </section>
    </article>
  </Page>
</template>
<script>
import SuccessAndError from '../../components/business/SuccessAndError'
import Collection from '@/components/Collection'
import Stepbar from '../../components/business/Stepbar'
import ApplyForm from '../../components/business/ApplyForm'
import base from '../../core/base'
import Applyheader from '../../components/base/Applyheader.vue'
export default {
  name: 'public',
  mixins: [base],
  components: {
    Collection,
    Stepbar,
    ApplyForm,
    SuccessAndError,
    Applyheader
  },
  data () {
    return {
      prodataStatus: false,
      currentBusiness: '',
      StepStatus: 1,
      citykey: '',
      city: '',
      area: '',
      prodata: [],
      currentSelect: -1,
      changeValue: '',
      provider: {
        current: 1,
        defaultPageSize: 12
      },
      submitData: {
        address: '',
        area: '',
        business_type: '',
        city: '',
        nation: '',
        order_desc: '',
        order_type: '预约',
        person_type: '',
        platform_channel: '门户',
        platform_type: '网络',
        province: '',
        reserve_handle_time: '',
        reserve_person: '',
        reserve_provider: '',
        service_type: '',
        sponsor_idcard_no: '',
        sponsor_idcard_type: '身份证',
        sponsor_name: '',
        sponsor_phone: '',
        town: ''
      }
    }
  },
  activated () {
    this.goLoginTip(this.$route.path)
    var phone = this.$Cookies.get('phone')
    if (phone !== '' && phone !== undefined) {
      if (this.$Cookies.get('level') !== '1') {
        this.$message.info('请先实名认证')
        window.location.replace('/my#/workorder')
      }
      this.submitData.sponsor_phone = phone
      this.submitData.sponsor_idcard_no = this.$Cookies.get('idcard_no')
      this.submitData.sponsor_name = this.$Cookies.get('user_name')
    }
    this.StepStatus = 1
    this.submitData.service_type = this.post.service_type
    this.submitData.platform_channel = this.post.platform_channel
    this.submitData.platform_type = this.post.platform_type
    this.searchorg()
  },
  // mounted () {
  //   this.goLoginTip(this.$route.path)
  //   this.submitData.sponsor_phone = this.$getCookie('phone')
  //   this.StepStatus = 1
  //   this.submitData.service_type = this.post.service_type
  //   this.submitData.platform_channel = this.post.platform_channel
  //   this.submitData.platform_type = this.post.platform_type
  // },
  methods: {
    selectOrg (key, currentSelect) {
      this.submitData.reserve_provider = key
      this.currentSelect = currentSelect
    },
    setStep (no) {
      this.StepStatus = no
    },
    searchorg () {
      var that = this
      var judge = this.currentBusiness
      if (this.$route.name !== '鉴定预约' && this.$route.name !== '公证预约') {
        judge = ''
      }
      var str = '?provider_type=' + this.post.type + '&'
      str += 'area=' + this.submitData.area + '&city=' + this.submitData.city + '&business_type=' + judge // + '&provider_type=' + this.provider_typ
      this.$api.get('/api/provider' + str).then(function (res) {
        that.prodata = res.data.collection
        if (that.prodata.length === 0) {
          that.prodataStatus = true
        } else {
          that.prodataStatus = false
        }
      })
    },
    next () {
      if (this.StepStatus === 2) {
        if (this.submitData.reserve_provider !== '') {
          this.StepStatus = 3
        } else if (this.submitData.city !== '') {
          this.StepStatus = 3
        } else {
          this.$message.info('市区为必选项')
        }
      }
    },
    handleChange (value, options) {
      this.changeValue = ''
      if (value === '') {
        this.submitData.city = ''
        if (this.submitData.business_type === '') {
          this.prodata = []
        }
        this.searchorg()
        return
      }
      this.submitData.area = ''
      this.citykey = value
      var obj = {}
      obj = options.find((item) => {
        return item.district_id === value
      })
      this.submitData.city = obj.district_name
      this.searchorg()
    },
    areaChange (value, options) {
      if (value === '') {
        this.submitData.area = ''
        this.searchorg()
        return
      }
      var obj = {}
      obj = options.find((item) => {
        return item.district_id === value
      })
      this.submitData.area = obj.district_name
      this.searchorg()
    },
    businessChange (value, options) {
      if (value === '') {
        this.submitData.business_type = ''
        this.currentBusiness = ''
        this.searchorg()
        return
      }
      this.currentBusiness = value
      this.submitData.business_type = value
      this.searchorg()
    },
    onChange (page) {
      this.provider.current = page
    }
  }
}
</script>
<style src="../../assets/css/apply.css"></style>
