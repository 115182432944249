<template>
  <div class="apply-stepbar">
    <a-row>
      <a-col :xs={span:24,offset:0}>
        <a-steps class="apply-head">
          <a-step :status="post.StepStatus> 0 ?'finsh' :'wait'" :title="post.title[0]">
            <a-icon slot="icon" type="solution"/>
          </a-step>
          <a-step :status="post.StepStatus>1 ?'finsh' :'wait'" :title="post.title[1]">
            <a-icon slot="icon" type="check-square"/>
          </a-step>
          <a-step :status="post.StepStatus> 2?'finsh' :'wait'" :title="post.title[2]">
            <a-icon slot="icon" type="form"/>
          </a-step>
          <a-step :status="post.StepStatus>3 ?'finsh' :'wait'" :title="post.title[3]">
            <a-icon slot="icon" type="smile-o" />
          </a-step>
        </a-steps>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import base from '@/core/base'
export default {
  name: 'Stepbar',
  mixins: [base]
}
</script>

<style scoped>

</style>
